import {StyleExtractor} from "@kubric/utils";
import BlockContent from "@sanity/block-content-to-react";
import {OutboundLink} from "gatsby-plugin-google-analytics";
import * as React from "react";

import * as styles from "./styles.module.scss";

// NOTE: sanity/block-content-to-react is deprecated, need to move to Portabletext
// TODO: migrate to portable text: https://github.com/portabletext/react-portabletext/blob/main/MIGRATING.md

const getUrlFromAsset = (asset, type, projectId, dataset) => {
  if (!asset || !type) {
    return null;
  }

  const rawUrl = asset._ref.split(`${type}-`)[1].split("-");
  const extension = rawUrl.pop();
  return `https://cdn.sanity.io/${type}s/${projectId}/${dataset}/${rawUrl.join(
    "-"
  )}.${extension}`;
};

const SanityRichText = ({blocks, className, theme, ...props}) => {
  const styler = new StyleExtractor(styles, theme);
  const serializers = {
    types: {
      break: (props) => {
        const {style} = props.node;
        if (style === "lineBreak") {
          return <br />;
        }
        return null;
      },
      image: (props) => {
        const {alt, name, asset} = props.node || {};
        const {projectId, dataset} = props.options;
        const imageUrl = getUrlFromAsset(asset, "image", projectId, dataset);
        return (
          <figure className={styler.get("figure")}>
            <img
              className={styler.get("image")}
              src={imageUrl}
              alt={alt || "image"}
            />
            {name && <figcaption>{name}</figcaption>}
          </figure>
        );
      },
      table: (props) => {
        const {rows = []} = props.node;
        const [headerRow, ...bodyRows] = rows;
        return (
          <table className={styler.get("table")}>
            <thead>
              <tr>
                {headerRow.cells.map(({content}) => (
                  <th>
                    <SanityRichText
                      renderContainerOnSingleChild
                      blocks={content}
                      className={styler.get("tableCell")}
                    />
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {bodyRows.map((row) => (
                <tr>
                  {row.cells.map(({content}) => (
                    <td>
                      <SanityRichText
                        renderContainerOnSingleChild
                        blocks={content}
                        className={styler.get("tableCell")}
                      />
                    </td>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
        );
      },
      audioObject: (props) => {
        const {caption, file} = props.node || {};
        const {projectId, dataset} = props.options;
        const audioUrl = getUrlFromAsset(
          file?.asset,
          "file",
          projectId,
          dataset
        );

        return (
          <figure className={styler.get("audio")}>
            {caption && <figcaption>{caption}</figcaption>}
            <audio controls src={audioUrl} />
          </figure>
        );
      },
      basicVideoObject: (props) => {
        const {caption, file} = props.node || {};
        const {projectId, dataset} = props.options;
        const videoUrl = getUrlFromAsset(
          file?.asset,
          "file",
          projectId,
          dataset
        );
        return (
          <figure className={styler.get("video")}>
            <video controls playsInline src={videoUrl} />
            {caption && <figcaption>{caption}</figcaption>}
          </figure>
        );
      },
    },
    marks: {
      link: (props) => {
        const {linkId, href} = props.mark || {};
        return (
          <OutboundLink
            href={href}
            referrerPolicy='no-referrer'
            rel='noopener noreferrer'
            target={href.startsWith("#") ? "_self" : "_blank"}
            id={linkId}
          >
            {props.children}
          </OutboundLink>
        );
      },
    },
  };

  if (!blocks) {
    return null;
  }

  return (
    <BlockContent
      className={className}
      blocks={blocks}
      serializers={serializers}
      projectId={process.env.SANITY_PROJECT_ID}
      dataset={process.env.SANITY_DATASET}
      {...props}
    />
  );
};

export default SanityRichText;
