import {get} from "@kubric/utils";
import {useLocation} from "@reach/router";
import {Link, graphql, useStaticQuery} from "gatsby";
import {OutboundLink} from "gatsby-plugin-google-analytics";
import React, {useEffect} from "react";
import Facebook from "react-feather/dist/icons/facebook";
import Instagram from "react-feather/dist/icons/instagram";
import Linkedin from "react-feather/dist/icons/linkedin";
import Twitter from "react-feather/dist/icons/twitter";
import Youtube from "react-feather/dist/icons/youtube";

import {useMediaQuery} from "../../hooks/useMediaQuery";
import "./footer.scss";

const FOOTER_QUERY = graphql`
  {
    allSanityFooterSection {
      nodes {
        copyright
        icons {
          type
          link
          _key
        }
        links {
          mText
          text
          url
          _key
        }
      }
    }
  }
`;

const Icon = {
  facebook: <Facebook />,
  twitter: <Twitter />,
  instagram: <Instagram />,
  youtube: <Youtube />,
  linkedin: <Linkedin />,
};

const Footer = ({type = "mason", mmv3, plainFooterBackground}) => {
  const data = useStaticQuery(FOOTER_QUERY);
  const {
    copyright,
    icons = [],
    links = [],
  } = get(data, "allSanityFooterSection.nodes.0", {});

  const isMobile = useMediaQuery("(max-width: 900px)");

  const location = useLocation();

  useEffect(() => {
    if (type === "modemagic" && location.pathname.includes("contact")) {
      document.body.classList.add("contactPageBody");
    }
    return () => document.body.classList.remove("contactPageBody");
  }, [type, location]);

  return (
    <div
      className={`footWrap full-bleed layouted${mmv3 ? " v3" : ""} ${
        plainFooterBackground && "plainBg"
      }`}
    >
      <div className='socialicons'>
        {/* OutboundLink is part of google analytics which tracks click on external link */}

        {icons.map(({type, link, _key}) => (
          <OutboundLink
            target='_blank'
            href={link}
            rel='noopener noreferrer'
            key={_key}
          >
            {Icon[type]}
          </OutboundLink>
        ))}
      </div>
      <div className='footlinks'>
        {links.map(({url, text, mText}) => {
          const isExternalLink = url.startsWith("http");
          const linkText = !isMobile ? text : mText;

          return isExternalLink ? (
            <OutboundLink href={url}>{linkText}</OutboundLink>
          ) : (
            <Link to={url}>{linkText}</Link>
          );
        })}
        {/* {type === "modemagic" && <Link to='/contact'>Contact</Link>} */}
      </div>
      <p>{copyright}</p>
    </div>
  );
};

export default Footer;
